import { Button, ButtonHoverSelector, ButtonKind, ButtonSize } from "@gadgetinc/widgets";
import { GitHubIcon } from "@gadgetinc/widgets/src/icons/GitHubIcon";
import { ThirdPartyIcon } from "@gadgetinc/widgets/src/icons/ThirdPartyIcon";
import { useStyletron } from "baseui";
import * as React from "react";
import { useLogin } from "./login.utils";
import { observer } from "mobx-react-lite";
import { asyncSafeEventHandler } from "../../lib/utils";
import { useAuthConfig } from "./AuthConfigProvider";

const SignInButton = observer((props: { children: React.ReactNode; icon: React.ReactNode; href: string }) => {
  const [_css, $theme] = useStyletron();
  const { bypassCfTurnstile } = useAuthConfig();
  const { form } = useLogin();

  return (
    <Button
      href={props.href}
      onClick={asyncSafeEventHandler(async (event) => {
        event.preventDefault();

        await form.signInWithOAuth(props.href, bypassCfTurnstile);
      })}
      startEnhancer={props.icon}
      kind={ButtonKind.tertiary}
      size={ButtonSize.large}
      disabled={form.showTurnstile || form.loading}
      overrides={{
        BaseButton: {
          style: {
            color: $theme.colors.contentPrimary,
            textDecoration: "none",
            backgroundColor: $theme.colors.alpha50,
            padding: $theme.sizing.scale300,
            [ButtonHoverSelector]: {
              backgroundColor: $theme.colors.alpha100,
              color: $theme.colors.contentPrimary,
              textDecoration: "none",
            },
            ":active:not(:disabled)": {
              backgroundColor: $theme.colors.alpha200,
              color: $theme.colors.contentPrimary,
              textDecoration: "none",
            },
            ":focus-visible": {
              color: $theme.colors.contentPrimary,
            },
          },
        },
      }}
    >
      {props.children}
    </Button>
  );
});

export const GoogleSignInButton = observer(() => {
  return (
    <SignInButton href="/auth/google" icon={<ThirdPartyIcon type="google" $size={20} />}>
      Continue with Google
    </SignInButton>
  );
});
export const GitHubSignInButton = observer(() => {
  return (
    <SignInButton href="/auth/github" icon={<GitHubIcon $size={20} />}>
      Continue with GitHub
    </SignInButton>
  );
});
