import { Column, Link } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import { observer } from "mobx-react-lite";
import React from "react";
import { asyncSafeEventHandler } from "../../lib/utils";
import { ContinueWithPasswordButton } from "./ContinueWithPasswordButton";
import { EmailInput } from "./EmailInput";
import { LegalTermsFooter } from "./LegalTermsFooter";
import { LoginNotifications } from "./LoginNotifications";
import { PasswordInput } from "./PasswordInput";
import { PasswordRequirements } from "./PasswordRequirements";
import { RequestPasswordResetButton } from "./RequestPasswordResetButton";
import { useLogin } from "./login.utils";
import { useAuthConfig } from "./AuthConfigProvider";

export const EmailLogin = observer(() => {
  const { bypassCfTurnstile } = useAuthConfig();
  const { form, createUser, signIn } = useLogin();
  const [_css, $theme] = useStyletron();

  const hasInputControls =
    form.shouldShowEmailInput ||
    form.shouldShowPasswordInput ||
    form.shouldShowPasswordRequirements ||
    form.shouldShowPasswordInput ||
    form.shouldShowRequestPasswordReset;

  return (
    <Column
      $as="form"
      $gap={$theme.sizing.scale600}
      onSubmit={asyncSafeEventHandler(async (event) => {
        event.preventDefault();
        if (form.signup) {
          await form.createUser(createUser, bypassCfTurnstile);
        } else {
          await form.signIn(signIn, bypassCfTurnstile);
        }
      })}
    >
      {hasInputControls && (
        <Column $gap={$theme.sizing.scale600}>
          {form.shouldShowEmailInput && <EmailInput />}
          {form.shouldShowPasswordInput && <PasswordInput newPassword={form.signup} />}
          {form.shouldShowPasswordRequirements && <PasswordRequirements />}
          {form.shouldShowPasswordInput && <ContinueWithPasswordButton />}
          {form.shouldShowRequestPasswordReset && <RequestPasswordResetButton />}
        </Column>
      )}
      <LoginNotifications />
      {form.shouldShowForgotPassword && (
        <Link
          href="#"
          onClick={(event) => {
            event.preventDefault();
            form.showPasswordReset();
          }}
        >
          Forgot password?
        </Link>
      )}
      <LegalTermsFooter />
    </Column>
  );
});
