import {
  Button,
  ButtonKind,
  ButtonSize,
  Column,
  Link,
  Row,
  expandBaseWebBorder,
  expandBorderRadii,
  expandPadding,
  marginVertical,
  paddingUtil,
} from "@gadgetinc/widgets";
import { AuthMarketingTheme } from "@gadgetinc/widgets/src/AuthTheme";
import { ThemeProvider, styled, useStyletron } from "baseui";
import React from "react";
import { Divider } from "../edit/editor/Divider";
import { AUTH_BOX_MAX_WIDTH, AuthBox } from "./AuthBox";
import { Breakpoint } from "./AuthLayout";
import { EmailLogin } from "./EmailLogin";
import { GitHubSignInButton, GoogleSignInButton } from "./SignInButtons";
import type { LoginPageProps } from "./login.utils";
import { FeatureRow, LoginContextProvider, StyledParagraph, StyledBold, StyledParagraphSmall, useLogin } from "./login.utils";
import { AuthConfigProvider } from "./AuthConfigProvider";
import { CloudflareTurnstile } from "./CloudflareTurnstile";
import { observer } from "mobx-react-lite";
import SimonBarnes from "../../../public/assets/simon-barnes.png";

const LoginPage = (props: LoginPageProps) => {
  return (
    <ThemeProvider theme={AuthMarketingTheme}>
      <AuthConfigProvider {...props.config}>
        <Row
          $style={{
            height: "auto",
            minHeight: "100vh",
            [Breakpoint.Large]: {
              flexDirection: "column",
            },
          }}
        >
          <LoginContextProvider {...props}>
            <CloudflareTurnstile />
            <LoginForm {...props} />
          </LoginContextProvider>
        </Row>
      </AuthConfigProvider>
    </ThemeProvider>
  );
};

export default LoginPage;

const LoginForm = observer((props: LoginPageProps) => {
  const [css, $theme] = useStyletron();
  const { form } = useLogin();

  const pageTitle = props.presentAsSignup ? "Sign up for Gadget" : "Log in to Gadget";
  const heading = props.presentAsSignup ? "Sign up for Gadget" : "Log in to Gadget";
  const subHeading = props.presentAsSignup ? (
    <>
      Create an account or <Link href="/auth/login">log in</Link>
    </>
  ) : (
    <>
      Don&lsquo;t have an account?{" "}
      <Button
        $as="a"
        href="/auth/signup"
        kind={ButtonKind.tertiary}
        size={ButtonSize.large}
        overrides={{
          BaseButton: {
            style: {
              fontSize: $theme.sizing.scale600,
              lineHeight: $theme.sizing.scale700,
            },
          },
        }}
      >
        Sign up
      </Button>
    </>
  );

  return form.showTurnstile ? null : (
    <>
      <AuthBox
        isPreLogin
        pageTitle={pageTitle}
        heading={heading}
        subHeading={<StyledParagraph $style={{ lineHeight: $theme.sizing.scale700 }}>{subHeading}</StyledParagraph>}
      >
        <Column $as="article" $style={{ maxWidth: AUTH_BOX_MAX_WIDTH }} $gap={$theme.sizing.scale900}>
          <Column $as="section" $style={{ width: "100%" }} $gap={$theme.sizing.scale600}>
            <GoogleSignInButton />
            <GitHubSignInButton />
          </Column>
          <Divider
            $style={{
              ...marginVertical(0),
              borderColor: $theme.colors.borderOpaque,
            }}
          />
          <EmailLogin />
        </Column>
      </AuthBox>

      {props.presentAsSignup && (
        <Column
          $align="center"
          $style={{
            background: "radial-gradient(56.85% 56.85% at 54.52% 52.58%, #FDFDFD 0%, #E7E7E7 100%)",
            alignItems: "center",
            height: "auto",
            minHeight: "100vh",
            flex: 1,
            [Breakpoint.Large]: { display: "none" },
            [Breakpoint.Medium]: paddingUtil($theme.sizing.scale1600, $theme.sizing.scale1000),
            ...expandPadding($theme.sizing.scale1400),
          }}
        >
          <FlatCard
            $as="article"
            $border
            $style={{ backgroundColor: $theme.colors.mono100, color: "#64F196", ...expandPadding($theme.sizing.scale800) }}
          >
            <Column $gap={$theme.sizing.scale800}>
              <Column $as="header" $gap={$theme.sizing.scale600} $style={{}}>
                <StyledBold $style={{ letterSpacing: "-0.2px" }}>
                  <span style={{ color: $theme.colors.primary }}>Using Gadget is at least 4-5x faster</span>{" "}
                  <span style={{ color: $theme.colors.primary400 }}>than setting up an app manually.</span>
                </StyledBold>
                <Row $gap="13px" style={{ alignItems: "flex-end", alignSelf: "stretch" }}>
                  <Row
                    $align="center"
                    $style={{
                      ...expandBorderRadii($theme.sizing.scale400),
                      boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.07)",
                      backgroundImage: "linear-gradient(180deg, #E6E6E6 0%, #B2B2B2 100%)",
                      height: "50px",
                      width: "50px",
                      overflow: "hidden",
                      padding: "1px",
                    }}
                  >
                    <img className={css({ ...expandBorderRadii("9px") })} src={SimonBarnes} />
                  </Row>
                  <Column>
                    <StyledBold style={{ fontSize: $theme.sizing.scale600 }}>Simon Barnes</StyledBold>
                    <StyledParagraphSmall>Partner</StyledParagraphSmall>
                  </Column>
                </Row>
              </Column>
            </Column>
          </FlatCard>
          <FlatCard>
            <Row $gap={$theme.sizing.scale800}>
              <Column $gap={$theme.sizing.scale500}>
                <FeatureRow>Node.js backend</FeatureRow>
                <FeatureRow>Managed database</FeatureRow>
                <FeatureRow>Generated API & client</FeatureRow>
                <FeatureRow>React frontend</FeatureRow>
              </Column>
              <Column $gap={$theme.sizing.scale500}>
                <FeatureRow>Background actions</FeatureRow>
                <FeatureRow>Git-based source control</FeatureRow>
                <FeatureRow>3rd party API connections</FeatureRow>
                <FeatureRow>Hosted & scaled</FeatureRow>
              </Column>
            </Row>
          </FlatCard>
        </Column>
      )}
    </>
  );
});

const FlatCard = styled<{ $border?: boolean }, "div">("div", ({ $theme, $border }) => {
  return {
    ...expandPadding($theme.sizing.scale800),
    ...expandBorderRadii($theme.sizing.scale700),
    ...($border
      ? {
          ...expandBaseWebBorder({
            borderWidth: "1px",
            borderColor: "#D4D4DB",
            borderStyle: "solid",
          }),
          boxShadow: "0px 15px 52px -15px rgba(25, 28, 33, 0.05), 0px 4px 6px 0px rgba(0, 0, 0, 0.05)",
        }
      : {}),
    height: "auto",
    maxWidth: "540px",
  };
});
