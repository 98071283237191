import React, { createContext, useContext } from "react";

export interface AuthConfig {
  servicesRootURL: string;
  cfTurnstileSiteKey: string;
  bypassCfTurnstile: boolean;
}

export const AuthConfigContext = createContext<AuthConfig | null>(null);

export const AuthConfigProvider = (props: { children: React.ReactNode } & AuthConfig) => {
  return <AuthConfigContext.Provider value={props}>{props.children}</AuthConfigContext.Provider>;
};

export const useAuthConfig = () => {
  const context = useContext(AuthConfigContext);
  if (!context) {
    throw new Error("useAuthConfig must be used within an AuthConfigProvider");
  }

  return context;
};
