import { Button, ButtonKind, ButtonSize, Column, Input, InputSize } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import { observer } from "mobx-react-lite";
import React from "react";
import { asyncSafeEventHandler } from "../../lib/utils";
import { FormField } from "./FormField";
import { useLogin } from "./login.utils";

export const EmailInput = observer(() => {
  const { form, checkEmail } = useLogin();
  const [_css, $theme] = useStyletron();

  if (form.resetRequested) {
    return null;
  }

  const errorMessage = !form.email ? "Enter an email address" : "Enter a valid email address";
  return (
    <Column $gap={$theme.sizing.scale600}>
      <FormField error={form.shouldShowEmailError && form.hasEmailError ? errorMessage : undefined}>
        <Input
          autoFocus
          name="username"
          autoComplete="username"
          id="username"
          dataTestId="username"
          required
          placeholder="Email"
          aria-label="Email"
          type="email"
          value={form.email}
          size={InputSize.large}
          disabled={form.showTurnstile || form.loading}
          onKeyDown={asyncSafeEventHandler(async (event) => {
            if (form.shouldShowContinueEmail && event.key === "Enter") {
              event.preventDefault();
              await form.checkEmail(checkEmail);
            }
          })}
          onChange={(event) => form.setEmail((event.target as HTMLInputElement).value)}
          error={form.shouldShowEmailError && form.hasEmailError}
        />
      </FormField>

      {form.shouldShowContinueEmail && <ContinueWithEmailButton />}
      {form.shouldShowResendLink && <ResendLinkButton />}
    </Column>
  );
});

const ResendLinkButton = observer(() => {
  const { form, resendLink } = useLogin();
  return (
    <Button
      isLoading={form.loading}
      type="button"
      disabled={!form.validEmail}
      kind={ButtonKind.primary}
      size={ButtonSize.large}
      data-testid="resendLink"
      onClick={asyncSafeEventHandler(async () => {
        await form.resendLink(resendLink);
      })}
    >
      Resend link
    </Button>
  );
});

const ContinueWithEmailButton = observer(() => {
  const { form, checkEmail } = useLogin();
  return (
    <Button
      disabled={form.showTurnstile || form.loading}
      type="button"
      data-testid="continue-with-email"
      onClick={asyncSafeEventHandler(async () => {
        await form.checkEmail(checkEmail);
      })}
      kind={ButtonKind.secondary}
      size={ButtonSize.large}
    >
      Continue with email
    </Button>
  );
});
