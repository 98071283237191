import { Button, ButtonKind, ButtonSize } from "@gadgetinc/widgets";
import { observer } from "mobx-react-lite";
import React from "react";
import { asyncSafeEventHandler } from "../../lib/utils";
import { useLogin } from "./login.utils";

export const RequestPasswordResetButton = observer(() => {
  const { form, requestReset } = useLogin();

  return form.resetRequested ? null : (
    <Button
      isLoading={form.loading}
      data-testid="sendResetLink"
      type="button"
      disabled={!form.validEmail}
      kind={ButtonKind.primary}
      size={ButtonSize.large}
      onClick={asyncSafeEventHandler(async () => {
        await form.requestReset(requestReset);
      })}
    >
      Send reset link
    </Button>
  );
});
