import type { TurnstileInstance } from "@marsidev/react-turnstile";
import { Turnstile } from "@marsidev/react-turnstile";
import { observer } from "mobx-react-lite";
import { useAuthConfig } from "./AuthConfigProvider";
import React, { useEffect, useRef } from "react";
import { useLogin } from "./login.utils";
import { Column } from "@gadgetinc/widgets";

export const CloudflareTurnstile = observer(() => {
  const ref = useRef<TurnstileInstance>(null);

  const config = useAuthConfig();
  const { form } = useLogin();

  useEffect(() => {
    form.setCfTurnstile(ref.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, ref.current]);

  return (
    <>
      {!config.bypassCfTurnstile && (
        <Column $style={{ display: form.showTurnstile ? "flex" : "none", flex: 1, alignItems: "center" }}>
          <Turnstile
            ref={ref}
            options={{
              theme: "light",
            }}
            siteKey={config.cfTurnstileSiteKey}
            onSuccess={(token) => {
              form.setCfTurnstileToken(token);
              form.setCfTurnstileRequiresInteraction(false);
            }}
            onBeforeInteractive={() => {
              form.setCfTurnstileRequiresInteraction(true);
            }}
          />
        </Column>
      )}
    </>
  );
});
