import { marginVertical } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React from "react";
import type { StyleObject } from "styletron-react";

export const Divider = (props: { $style?: StyleObject }) => {
  const [css, $theme] = useStyletron();
  return (
    <hr
      className={css({
        ...$theme.borders.border200,
        ...marginVertical($theme.sizing.scale200),
        borderBottom: "none",
        width: "100%",
        ...(props.$style ?? {}),
      })}
    />
  );
};
